import React, { useState } from "react";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { toast } from "react-toastify";
import downloadFile from "../../utils/download-file";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export default function PdfPreview({ file }) {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const close = () => setIsOpen(false);
    const open = () => {
        setIsOpen(true);
        setData(null);
        setLoading(true);

        downloadFile(file)
            .then(d => {
                setData(d);
                console.log({
                    data: d,
                });
            })
            .catch(() => {
                toast.error(t("image_preview_error"));
                close();
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <span onClick={open}>
                {t("image_preview")}
            </span>

            <ModalTransition>
                {isOpen && (
                    <Modal
                        isLoading={loading}
                        onClose={close}
                        isChromeless
                        width="100vw"
                        height="100vh"
                    >
                        {data && <PdfViewer>
                            <embed type="application/pdf" src={URL.createObjectURL(data)} width="100%" height="100%"></embed>
                        </PdfViewer>}
                    </Modal>
                )}
            </ModalTransition>
        </>
    );
}

const PdfViewer = styled.div`
    max-width: 100%;
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;