import React, { useEffect, useState } from "react";
import useDocumentFields from "../../hooks/use-document-fields";
import Spinner from "../ui/spinner";
import TextField from "@atlaskit/textfield";
import { Field } from "@atlaskit/form";
import FormField from "../forms/form-field";
import { format } from "date-fns";
import toBoolean from "../../utils/to-boolean";
import SectionMessage from "@atlaskit/section-message/section-message";
import SectionMessageAction from "@atlaskit/section-message/section-message-action";
import styled from "styled-components";
import { isEditable, isVisible } from "./document";
import { useAuth } from "../../providers/auth-provider";

function NewDocumentForm(props) {
    const { documentTypeId, error, quickForm = false, onChange, onBlur, initialValues = {}, data = {}, propagateFields, fields, isPending, isDisabled = false, contextDocument, runWorkflow, isNested = false, onNestedSubmit } = props;
    const [hidden, setHidden] = useState([]);
    const [hiddenCreate, setHiddenCreate] = useState([]);
    const { user } = useAuth();

    const currentRoot = {
        rawValues: fields?.map(i => ({
            document_type_field_id: i?.id,
            value: data[i?.name] ?? null,
        })) ?? null,
        values: fields?.map(i => ({
            document_type_field_id: i?.id,
            value: data[i?.name] ?? null,
        }))?.reduce((acc, i) => {
            acc[i.document_type_field_id] = i.value;
            return acc;
        }, {}) ?? null,
    };

    const document = {
        values: data,
    };

    useEffect(() => {
        const h = [];
        const hc = [];
        fields?.filter(f => f?.field?.type === "section")?.forEach(field => {
            try {
                const options = JSON.parse(field?.options[0]);
                if (options?.hidden) {
                    h.push(field?.sectionId);
                }
                if (options?.hiddenCreate) {
                    hc.push(field?.sectionId);
                }
            } catch (e) {
                //
            }
        });

        fields?.forEach(field => {
            if (field.field.type == "select" || field.field.type == "multiselect") {
                const rawOptions = field?.select_options;
                let optionsData = {};
                try {
                    optionsData = JSON.parse(rawOptions);
                } catch (e) {
                    console.error(e);
                }

                if (optionsData?.defaultValue) {
                    data[field?.name] = optionsData?.defaultValue;
                }

            } else {
                const rawOptions = field?.options?.[0];
                let optionsData = {};
                try {
                    optionsData = JSON.parse(rawOptions);
                } catch (e) {
                    console.error(e);
                }

                if (optionsData?.defaultValue) {
                    data[field?.name] = optionsData?.defaultValue;
                }
            }


            if (field.field.type == "date" && !data[field?.name]) {
                data[field?.name] = format(new Date(), "yyyy-MM-dd");
            }

        });
        setHidden(h);
        setHiddenCreate(hc);
    }, [fields?.length, documentTypeId]);

    const toggleSection = sectionId => {
        if (hidden.includes(sectionId)) {
            setHidden(h => h.filter(i => i != sectionId));
        }
        else {
            setHidden(h => [...h, sectionId]);
        }
    };

    if (isPending) {
        return <Spinner />;
    }

    const onFieldChanged = (fieldName, value) => {
        fields?.map(field => {
            let column = {};

            try {
                column = JSON.parse(field?.options);
            } catch (err) {
                // console.log(err);
            }

            if (!column?.computed) return;

            const isA = column?.computed?.a === fieldName;
            const isB = column?.computed?.b === fieldName;

            if (!(isA || isB)) return;

            let aValue = isA ? value : data[column?.computed?.a];
            let bValue = isB ? value : data[column?.computed?.b];
            const op = column?.computed?.op;

            if (!aValue || !bValue || !op) return;

            if (String(aValue).includes(",")) {
                aValue = Number(String(aValue).replaceAll(",", "."));
            }

            if (String(bValue).includes(",")) {
                bValue = Number(String(bValue).replaceAll(",", "."));
            }

            let newValue;
            switch (op) {
                case "mult":
                    if (column?.computed?.fixed) {
                        newValue = (Number(aValue) * Number(bValue)).toFixed(column?.computed?.fixed ?? 2);
                    } else {
                        newValue = (Number(aValue) * Number(bValue)).toFixed(column?.computed?.fixed ?? 2);
                    }

                    break;
                default:
                    return;
            }

            newValue = String(newValue)?.replaceAll(".", ",");

            onFieldChanged(field?.name, newValue);
            onChange(field, newValue);
        });
    };

    return <>
        {fields
            .filter(f => !toBoolean(f.hidden) && !toBoolean(f.read_only))
            .filter(i => isVisible(i, currentRoot, user))
            .filter(i => isEditable(i, currentRoot, user))
            .filter(f => !f?.group)
            .filter(f => quickForm ? toBoolean(f.quick_form) : true)
            .filter(f => f?.field?.type !== "list")
            .filter(f => f?.field?.type !== "widgets")
            // .filter(f => f?.field?.type !== "message")
            .map(field => {
                return <NewFormField
                    key={field?.name} {...props}
                    field={field} error={error} fields={fields} initialValues={initialValues} hiddenCreate={hiddenCreate}
                    currentRoot={currentRoot}
                    onFieldChanged={onFieldChanged}
                    onNestedSubmit={onNestedSubmit}
                />;
            })}
    </>;
}

export default NewDocumentForm;

const SectionMessageWrapper = styled.div`
margin-top: 30px;
`;

export const Divider = styled.hr`
    border: none;
    border-top: 1px solid #ddd;
    padding: 0px 0;
    margin: 10px 0;
`;

export function Heading(props) {
    const size = props?.size ?? "h5";
    const label = props?.label ?? null;

    if (!label) return null;

    switch (size) {
        case "h1":
            return <h1>{label}</h1>;
        case "h2":
            return <h2>{label}</h2>;
        case "h3":
            return <h3>{label}</h3>;
        case "h4":
            return <h4>{label}</h4>;
        case "h5":
            return <h5>{label}</h5>;
        case "h6":
            return <h6>{label}</h6>;
    }

    return <p>{label}</p>;
}

export function FieldsGroup(props) {
    const { field, fields } = props;


    const groupFields = fields?.filter(f => f?.group === field?.name) ?? [];
    const sorted = groupFields?.sort((a, b) => parseInt(a?.group_lp) - parseInt(b?.group_lp));
    const count = groupFields?.length ?? 0;

    if (count <= 0) return null;

    return <div style={{
        display: "flex",
        gap: "10px",
        width: "100%",
    }}>
        {sorted?.map(gf => {
            return <div key={`gf-${gf?.name}`} style={{
                flex: gf?.group_flex ?? 1,
            }}>
                <NewFormField {...props} field={gf} isGroup />
            </div>;
        })}
    </div>;
}

function NewFormField(props) {
    const { user } = useAuth();
    const { field, error, documentTypeId, hidden, currentRoot, quickForm = false, onChange, onBlur, initialValues = {}, data = {}, propagateFields, fields, isPending, isDisabled = false, contextDocument, runWorkflow, hiddenCreate, isGroup = false, isNested = false, onNestedSubmit, onFieldChanged } = props;
    let inputProps = {
        key: field.id,
        name: field.name,
        // label: field?.field?.type === "checkbox" ? null : field.label,
        isRequired: !!field.required,
        defaultValue: undefined,
        isInvalid: error && error?.errors ? error?.errors[field.name] : false,
        options: field?.options || [],
        table_column: field?.table_column || [],
        isDisabled: toBoolean(field?.read_only ?? 0) || isDisabled,
    };

    if (initialValues[field.name]) {
        inputProps.defaultValue = initialValues[field.name];
    }

    if (field.field.type == "divider") {
        if (hidden?.includes(field?.sectionId) || hiddenCreate?.includes(field?.sectionId)) {
            return null;
        }
        return <Divider />;
    }

    if (field?.field?.type == "group") {
        return <div>
            <FieldsGroup
                {...props}
                field={field}
                fields={fields.filter(f => !toBoolean(f.hidden) && !toBoolean(f.read_only))
                    .filter(i => isVisible(i, currentRoot, user))
                    .filter(i => isEditable(i, currentRoot, user))
                    .filter(f => quickForm ? toBoolean(f.quick_form) : true)
                    .filter(f => f?.field?.type !== "list")
                    .filter(f => f?.field?.type !== "widgets")}
                error={error}
            />
        </div>;
    }

    if (field.field.type == "heading") {
        if (hidden?.includes(field?.sectionId) || hiddenCreate?.includes(field?.sectionId)) {
            return null;
        }

        const rawOptions = field?.options?.[0];
        let optionsData = {};
        try {
            optionsData = JSON.parse(rawOptions);
        } catch (e) {
            console.error(e);
            //
        }

        return <Heading size={optionsData?.size} label={field.label} />;
    }

    if (field.field.type === "message") {
        if (hidden?.includes(field?.sectionId) || hiddenCreate?.includes(field?.sectionId)) {
            return null;
        }
        const rawOptions = field?.options?.[0];
        let optionsData = {};
        try {
            optionsData = JSON.parse(rawOptions);
        } catch (e) {
            console.error(e);
            //
        }

        if (!optionsData?.body || optionsData?.body?.length == 0) {
            return null;
        }

        return <SectionMessageWrapper>
            <SectionMessage
                title={optionsData?.title}
                appearance={optionsData?.appearance}
                actions={optionsData?.actions?.map(a => (
                    <SectionMessageAction key={a.label} href={a?.href}>{a?.label}</SectionMessageAction>
                ))}
            >
                {optionsData?.body}
            </SectionMessage>
        </SectionMessageWrapper>;
    }

    return <>
        <FormField key={field.id}
            isNested={isNested}
            isNew
            inputProps={{
                ...inputProps
            }}
            field={field}
            onChange={(field, d) => {
                onChange(field, d);
                onFieldChanged(field?.name, d);
            }}
            onBlur={() => {
                onBlur && onBlur(field);
            }}
            error={error && error?.errors ? error?.errors[field.name] : false}
            currentRoot={currentRoot}
            spacer
            contextDocument={contextDocument}
            propagateFields={propagateFields}
            onSectionToggle={sectionId => toggleSection(sectionId)}
            sectionHidden={hidden?.includes(field?.sectionId)}
            sectionHiddenCreate={hiddenCreate?.includes(field?.sectionId)}
            isGroup
            onNestedSubmit={onNestedSubmit}
            value={data[field.name]} />
    </>;
}